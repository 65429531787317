<template>
    <div>
<!--        <button class="btn03 show_mini_ifram" @click="setIsShow">게임 현황판 보기</button>-->

            <slot></slot>

    </div>
</template>

<script>
    export default {
        name: "LeisureGameIframView",
        data(){
          return {
              isShow:true,
          }
        },
        methods:{
            setIsShow(){
                this.isShow = !this.isShow
            }
        }
    }
</script>

<style scoped>
.showFram{
    display: block!important;
}
</style>